import React, { useState, useEffect } from 'react';
import { Sale } from "./Sale";
import { getOrders } from './getOrders';
import { RunescapeItem } from "../../../types"; // Adjust the import path as necessary

type RunescapeItemArray = RunescapeItem[];

const AnimatedSlash = () => {
    const characters = ['/', '-', '\\', '|'];
    const [currentChar, setCurrentChar] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentChar((prevChar) => (prevChar + 1) % characters.length);
        }, 200);

        return () => clearInterval(intervalId);
    }, []);

    return <code>{characters[currentChar]}</code>;
};

export function LiveSales() {
    const [sales, setSales] = useState<RunescapeItemArray | null>(null);

    useEffect(() => {
        getOrders().then((orders) => {
            setSales(orders);
        }).catch((error) => {
            console.error('Failed to fetch orders:', error);
        });
    }, []);

    if (sales === null) {
        return <div>Loading...</div>;
    }

    // Create an array of length 6, fill with sales data or null
    const displaySales = Array.from({ length: 6 }, (_, index) => sales[index] || null);

    return (
        <div id="live-sales" className="mockup-code w-full max-w-[800px]">
            {displaySales.map((saleInfo, index) => (
                saleInfo ? <Sale key={index} saleInfo={saleInfo} /> : <pre key={index} data-prefix=">" className="empty-row"><code></code></pre>
            ))}
            <pre data-prefix="$" className="bg-warning text-warning-content flex items-center">
                <code>live sales</code>
                <span className="flex-grow"></span>
                <AnimatedSlash />
            </pre>
        </div>
    );
}
