///gold/buy/{wallet_id}/

export const getGoldBuyOrders = async (wallet_id:string) => {
    console.log('[get gold buy order] Requesting gold buy orders...');

    const token = localStorage.getItem('jwt');

    if (!token) {
      console.error('[get gold buy order] No token found...');
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }

    const apiUrl = `/api/v1/gold/buy/${wallet_id}/`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        const responseData = await response.json();

        if (response.status === 200) {
            console.log('[get gold buy order] Request OK');
            return responseData;
        } else if(response.status === 204){
            console.log('[get gold buy order] No Content');
            return undefined
        }

        if (!response.ok) {
            console.log('[get gold buy order] Network response was not ok, status: ', response.status);
            return undefined;
        }

    } catch (error) {
        console.error('[get gold buy order] Failure:', error);
        return false;
    }
};
