import React from "react";

interface TabsMenuProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
}

export const TabsMenu: React.FC<TabsMenuProps> = ({activeTab, setActiveTab}) => {


    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
    };

    return (
        <div>
            <div role="tablist" className="tabs tabs-lifted">
                <a
                    role="tab"
                    className={`tab font-bold ${activeTab === 'Gold' ? 'tab-active [--tab-bg:base]' : ''}`}
                    // onClick={() => handleTabClick('Gold')}
                >
                    Gold
                </a>
                <a
                    role="tab"
                    className={`tab font-bold ${activeTab === 'Items' ? 'tab-active [--tab-bg:base]' : ''}`}
                    // onClick={() => handleTabClick('Items')}
                >
                    <div className="tooltip tooltip-bottom" data-tip="Unavaliable">
                        Items
                    </div>
                </a>
                <a
                    role="tab"
                    className={`tab font-bold ${activeTab === 'Accounts' ? 'tab-active [--tab-bg:base]' : ''}`}
                    // onClick={() => handleTabClick('Accounts')}
                >
                    <div className="tooltip tooltip-bottom" data-tip="Unavaliable">
                        Accounts
                    </div>
                </a>
            </div>
        </div>
    );
}