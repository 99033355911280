export const manualVerifyTransaction = async (order_id: string) => {
    const token = localStorage.getItem('jwt');

    if (!token) {
        console.error('[manual verify] No token found...');
        setTimeout(() => {
            window.location.reload();
        }, 4000);
        return;
    }

    console.log('[manual verify] Requesting manual verification...');
    console.log(order_id);

    const apiUrl = `/api/v1/gold/verify/`;

    const requestBody = JSON.stringify({ order_id });
    
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: requestBody,
        });

        if (!response.ok) {
            console.log('[manual verify] Network response was not ok, status: ', response.status);
            return false;
        }

        const responseData = await response.json();
        console.log('[manual verify] Status: ' + response.status);

        if (response.status === 200) {
            console.log('[manual verify] Request OK');
            return responseData;
        } else {
            console.log('[manual verify] Request BAD');
            return responseData;
        }
    } catch (error) {
        console.error('[manual verify] Failure', error);
        return false;
    }
};
