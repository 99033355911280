import React from 'react';
import { LiveSales } from './LiveSales/LiveSales';
import gnome_child from '../../common/images/gnome_child.png';

export function Hero() {
    return (
        <div className="hero bg-base-200 pb-6 w-full">
            <div className="hero-content text-left flex flex-col items-center w-full">
                <LiveSales/>
                <div className="w-full flex items-center justify-center">
                    <GnomeAvatar/>
                    <h1 className="text-3xl md:text-5xl font-bold text-center justify-center mt-4 lg:text-left ml-4">OSRS crypto<br/>marketplace</h1>
                </div>
            </div>
        </div>
    );
}

const GnomeAvatar = () => {
    return (
        <div className="avatar flex justify-center items-center pt-4">
            <div className="w-24 h-24 rounded-full ring-2 ring-red-500 ring-offset-2 ring-offset-white">
                <img src={gnome_child} alt="gnome_trader" className="w-full h-full object-cover rounded-full"/>
            </div>
        </div>
    );
}
