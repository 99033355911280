export const createUser = async (wallet_id: string, username: string) => {
    console.log('[create user] Creating user');
    const apiUrl = "/api/v1/user/";
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ wallet_id, username })
        });

        if (!response.ok) {
            console.log('[create user] Network response was not ok, status: ', response.status);
            return undefined;
        }

        if(response.status == 201){
            const responseJson = await response.json();
            console.log('[create user] Request OK');
            if (responseJson.jwt) {
                localStorage.setItem('jwt', responseJson.jwt);
            }
            return responseJson;
        } else {
            console.log('[create user] Request BAD');
            return undefined;
        }

    } catch (error) {
        console.error('[create user] Failed to create user:', error);
        return null;
    }
};
