import React from 'react';

interface TopNavProps {
    connectPhantom: () => Promise<void>;
    walletConnected: boolean;
    phantomInstalled: boolean;
}

interface ConnectWalletProps {
    connectPhantom: () => Promise<void>;
    phantomInstalled: boolean;
}

export function TopNav({connectPhantom, walletConnected, phantomInstalled}: TopNavProps) {
    return(

        <div className="bg-base-100 text-base-content sticky top-0 z-30 flex h-16 w-full justify-center bg-opacity-90 backdrop-blur transition-shadow duration-100 [transform:translate3d(0,0,0)]">
            <div className="navbar bg-red-500">
                <div className="flex-1">
                    <a className="btn btn-ghost text-xl text-white">gnome_trader</a>
                </div>
                <div className="flex-none">
                    <ul className="menu menu-horizontal px-1 text-white font-bold">
                    <li>
                        {walletConnected ? <WalletConnected/> : <ConnectWallet connectPhantom={connectPhantom} phantomInstalled={phantomInstalled}/>}
                    </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function ConnectWallet ({connectPhantom, phantomInstalled}: ConnectWalletProps) {

    if(phantomInstalled === true) {
        return(
            <a className='border-white border-2' onClick={() => connectPhantom()}>Connect Wallet</a>
        );
    } else {
        return(
            <a className='border-white border-2' onClick={() => {window.open('https://phantom.app/', '_blank');}}>Install Phantom</a>
        );
    }
}

function WalletConnected() {
    return(
        <a className='border-white border-2'>Connected</a>
    );
}