export const verifyNonce = async (wallet_id: string, nonce_value: string | undefined, signature: string) => {
    console.log('[nonce verify] verifying nonce');

    const apiUrl = `/api/v1/nonce/verify/`;

    const requestBody = JSON.stringify({ wallet_id, nonce_value, signature });

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: requestBody,
        });

        if (!response.ok) {
            console.log('[nonce verify] Network response was not ok, status: ', response.status);
            return false;
        }

        const responseData = await response.json();
        console.log('[verify nonce] Status: ' + response.status);

        if (response.status === 200) {
            console.log('[verify nonce] Request OK');
            if (responseData.jwt) {
                localStorage.setItem('jwt', responseData.jwt);
            }
            return responseData;
        } else {
            console.log('[verify nonce] Request BAD');
            return responseData;
        }
    } catch (error) {
        console.error('[nonce verify] Failed to verify nonce:', error);
        return false;
    }
};
