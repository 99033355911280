import React, { useState, useEffect } from 'react';
import { LandingPage } from '../landing_page/LandingPage';
import { TopNav } from '../common/TopNav';
import { LoadingCard } from '../common/loading_card/LoadingCard';
import { CreateAccount } from './create_account/CreateAccount';
import { getProvider } from '../utils/getProvider';
import { getUser } from '../utils/user/getUser';
import { verifyNonce } from '../utils/nonce/verifyNonce';
import { loginUser } from '../utils/user/loginUser';
import { PhantomProvider } from '../types';
import { MainMenu } from './main_menu/MainMenu';
import { GeneralProvider, useGeneral } from './general_context/GeneralContext';
import { WebSocketProvider } from './websocket_context/WebsocketContext';

import './App.css';

const App: React.FC = () => {
  const [provider, setProvider] = useState<PhantomProvider | undefined>(undefined);
  const [phantomInstalled, setPhantomInstalled] = useState<boolean>(false);
  const [walletConnected, setWalletConnected] = useState<boolean>(false);
  const [phantomId, setPhantomId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<JSON | undefined>(undefined);
  const [loginNonce, setLoginNonce] = useState<string | undefined>(undefined);
  
  const { setWalletId } = useGeneral();

  // Check for Phantom installation
  useEffect(() => {
    const checkPhantomInstallation = async () => {
      const maxAttempts = 10;
      const interval = 300;

      for (let attempt = 1; attempt <= maxAttempts; attempt++) {
        console.log("[get provider] Attempt:", attempt);
        const anyWindow = window as any;
        if (anyWindow.phantom?.solana?.isPhantom) {
          setProvider(getProvider());
          setPhantomInstalled(true);
          console.log('[get provider] Phantom installed!');
          return;
        }

        if (attempt === maxAttempts) {
          setPhantomInstalled(false);
          setLoading(false);
          console.log('[get provider] Phantom not installed max attempts reached...');
          return;
        }

        await new Promise(resolve => setTimeout(resolve, interval));
      }
    };
    checkPhantomInstallation();
  }, []);

  // Attempt to auto-connect Phantom wallet
  useEffect(() => {
    const autoConnectPhantom = async () => {
      console.log('[auto connect] Attempting to auto-connect Phantom wallet...');
      try {
        const resp = await provider?.connect({ onlyIfTrusted: true });
        if (resp) {
          const phantomString: any = resp.publicKey.toString();
          setPhantomId(phantomString);
          setWalletId(phantomString);  // Update context
          setWalletConnected(true);
          console.log('[auto connect] Wallet connected!');
        } else {
          setWalletConnected(false);
          setLoading(false);
          console.log('[auto connect] Wallet not connected...');
        }
      } catch(error) {
        setWalletConnected(false);
        setLoading(false);
        console.log('[auto connect] Wallet not connected due to error...');
        console.log('[auto connect] Error: ' + error);
      }
    };

    if (phantomInstalled) autoConnectPhantom();
  }, [phantomInstalled, provider, setWalletId]);

  useEffect(() => {
    const startLoginUser = async () => {
      let token = localStorage.getItem('jwt');

      if(token){
        console.log('[login user] JWT login user...');
        const userData = await getUser(phantomId);
        if(userData == undefined){
          console.log('[login user] JWT Login Failed...');
          token = null;
        } else {
          console.log('[login user] JWT Login Success!');
          setUserData(userData);
          setLoading(false);
        }
      }

      if(!token){  
        console.log('[login user] Getting login nonce...');
        const incomingData = await loginUser(phantomId);
        if (incomingData == undefined) {
          console.log('[login user] User doesnt exist...');
          setLoading(false);
        } else {
          console.log('[login user] User exists, setting nonce...');
          setLoginNonce(incomingData.nonce);
        }
      }
    }

    if (walletConnected) startLoginUser();
  }, [walletConnected, phantomId]);

  useEffect(() => {
    const signAndSendNonce = async () => {

      const toBase64 = (u8: any) => {
          return btoa(String.fromCharCode.apply(null, u8));
      }

      const requestUserData = async () => {
        setUserData(await getUser(phantomId));  
      }

      const encodedMessage = new TextEncoder().encode(loginNonce);

      let nonce_verified:any = [];

      try {
        const signedMessage = await provider?.signMessage(encodedMessage, "utf8"); 
        const signatureBase64 = toBase64(signedMessage?.signature);
        nonce_verified = await verifyNonce(phantomId, loginNonce, signatureBase64);
      } catch (error) {
        console.log('[nonce verify] Error:', error);
      }

      if(nonce_verified["verified"] == "True"){
        console.log('[nonce verify] Nonce verified!');
        await requestUserData();
        setTimeout(() => {
            setLoading(false);
        }, 2000);

      } else {
        console.log('[nonce verify] Nonce verification failed...');
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    }

    if(loginNonce !== undefined) signAndSendNonce();
  }, [loginNonce, phantomId]);

  const connectPhantom = async () => {
    try {
      const resp = await provider?.connect();
      const phantomString: any = resp?.publicKey.toString();
      setPhantomId(phantomString);
      setWalletId(phantomString);  // Update context
      setWalletConnected(true);
    } catch {
      setWalletConnected(false);
    }
  };

  const renderLoading = () => <LoadingCard />;
  const renderLandingPage = () => <LandingPage />;
  const renderCreateAccount = () => (
    <CreateAccount phantomId={phantomId} setUserData={setUserData} provider={provider} />
  );

  const renderLoggedIn = () => {
    return(
      <>
        {/* {phantomId !== "5iugsM1cgL2Lc3KoCokcYLXRmMAV6yYCpu8LHxa73EY5" && (
          <div className="absolute inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center z-50">
              
              <div className="badge badge-warning gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                EMERGENCY (GOLD RAN DRY!)
              </div>
              <div className='font-bold text-lg text-white'>
                <br></br>Ive had to temporarily close things, the service has been more popular than expected (thank you), it will be back up soon. <br></br> The site has been running barely 24 hours!
              </div>
          </div>
        )} */}
        <WebSocketProvider>
          <MainMenu/>
        </WebSocketProvider>
      </>
    );
  }

  const renderContent = () => {
    if (loading) return renderLoading();
    if (!walletConnected && !userData) return renderLandingPage();
    if (walletConnected && !userData) return renderCreateAccount();
    if (walletConnected && userData) return renderLoggedIn();
    return null;
  };

  return (
    <div className='bg-base-200 w-screen h-screen overflow-x-hidden'>
      <TopNav connectPhantom={connectPhantom} walletConnected={walletConnected} phantomInstalled={phantomInstalled} />
      {renderContent()}
    </div>
  );
};

const AppWrapper: React.FC = () => (
  <GeneralProvider>
    <App />
  </GeneralProvider>
);

export default AppWrapper;
