import React from "react";
import discord_logo from '../../common/images/discord_logo.png'
import gnome_child from '../../common/images/gnome_child.png'


export function DiscordLink() {
    return(
        <div className="flex flex-row items-center justify-center bg-base-200 py-12">
            <div className="stats shadow">
                <div className="stat">
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center">
                            <div className="prose p-2 pl-4">
                                <blockquote>Join the <a target="_blank" href="https://discord.gg/AnHettnTRH">discord</a></blockquote>
                            </div>
                            <DiscordLogo/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const DiscordLogo = () => {
    return(
        <img src={discord_logo} alt="osrs_gold" className="w-[34px] h-[25px]"/>
    )
}
