import React from "react";
import gnome_child from '../images/gnome_child.png';

export const LoadingCard = () => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white z-50">
            <div className="flex flex-col items-center justify-center">
                <GnomeAvatar/>
                <a className="btn bg-red-500 text-xl text-white m-12">gnome_trader</a>
                <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-12 w-12 mt-4"></div>
                <style>{`
                    .loader {
                        border-top-color: #EB7736;
                        animation: spin 1s linear infinite;
                    }

                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}</style>
            </div>
        </div>
    );
}

const GnomeAvatar = () => {
    return (
        <div className="avatar flex justify-center items-center p-4">
            <div className="w-24 h-24 rounded-full ring-2 ring-red-500 ring-offset-2 ring-offset-white">
                <img src={gnome_child} alt="gnome_trader" className="w-full h-full object-cover rounded-full"/>
            </div>
        </div>
    );
}