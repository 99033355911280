import React, { useState, useEffect } from "react";
import { createUser } from "../../utils/user/createUser";
import { PhantomProvider } from "../../types";
import { verifyNonce } from "../../utils/nonce/verifyNonce";
import { getUser } from "../../utils/user/getUser";

interface CreateAccountProps {
    phantomId: string;
    setUserData: React.Dispatch<React.SetStateAction<JSON | undefined>>;
    provider: PhantomProvider | undefined;
}

type CreateButtonProps = {
    loading: boolean;
    onClick: () => void;
};

export function CreateAccount({phantomId, setUserData, provider}: CreateAccountProps) {
    const [inputUsername, setInputUsername] = useState<string>('');
    const [nonce, setNonce] = useState(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [created, setCreated] = useState<boolean>(false);

    useEffect(() => {
        const signAndSendNonce = async () => {

            const toBase64 = (u8: any) => {
                return btoa(String.fromCharCode.apply(null, u8));
            }

            const requestUserData = async () => {
                setUserData(await getUser(phantomId));
            }

            const encodedMessage = new TextEncoder().encode(nonce);

            let nonce_verified:any = [];

            try {
                const signedMessage = await provider?.signMessage(encodedMessage, "utf8"); 
                const signatureBase64 = toBase64(signedMessage?.signature);
                nonce_verified = await verifyNonce(phantomId, nonce, signatureBase64);

            } catch (error) {
                setTimeout(() => {
                    window.location.reload();
                  }, 4000);
            }

            if(nonce_verified["verified"] == "True"){
                setCreated(true);
                setTimeout(() => {
                    requestUserData();
                }, 2000);

            } else {
                setTimeout(() => {
                    window.location.reload();
                  }, 4000);
            }
        }

        if(nonce !== undefined) signAndSendNonce();
    }, [nonce]);

    function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value.replace(/\s+/g, '').slice(0, 16);
        setInputUsername(newValue);
    }

    function handleCreateAccount() {
        const createAccount = async () => {
            setLoading(true);
            const nonceData = await createUser(phantomId, inputUsername);
            setNonce(nonceData["nonce"]);
        }

        createAccount();
    }

    return (
        <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="mt-6">
                <p>Enter a username to create an account:</p>
            </div>
            <div className="flex flex-col justify-center m-6">
                <label className="input input-bordered flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
                    </svg>
                    <input type="text" className="grow" placeholder="Username" value={inputUsername} onChange={handleInput}/>
                </label>
            </div>
            {created == false ? <CreateButton loading={loading} onClick={() => {handleCreateAccount()}}/> : <CreatedButton/>}
            
        </div>
    );
}

function CreateButton({ loading, onClick }: CreateButtonProps) {
    if (loading) {
        return (
            <button className="btn" disabled>
                <span className="loading loading-spinner"></span>
                Creating...
            </button>
        );
    } else {
        return (
            <button className="btn px-12" onClick={onClick}>
                Create
            </button>
        );
    }
}

function CreatedButton(){
    return(
        <button className="btn btn-success px-12">
            Created!
        </button>
    )
}
