import React, { createContext, useContext, useState, ReactNode } from 'react';

interface GeneralContextProps {
  walletId: string;
  setWalletId: (walletId: string) => void;
  // Add other global states and setters here
}

const GeneralContext = createContext<GeneralContextProps | undefined>(undefined);

export const GeneralProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [walletId, setWalletId] = useState<string>('');

  return (
    <GeneralContext.Provider value={{ walletId, setWalletId }}>
      {children}
    </GeneralContext.Provider>
  );
};

export const useGeneral = (): GeneralContextProps => {
  const context = useContext(GeneralContext);
  if (!context) {
    throw new Error('useGeneral must be used within a GeneralProvider');
  }
  return context;
};
