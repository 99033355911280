import React from "react";

export const SolanaSVG: React.FC = () => {
    return(
        <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 397.7 311.7"
        enableBackground="new 0 0 397.7 311.7"
        xmlSpace="preserve"
        style={{ width: '25px', height: '25px' }} // Set desired width and height here
        >
            <defs>
                <linearGradient id="SVGID_1_" x1="360.879" y1="351.455" x2="141.213" y2="-69.294" gradientTransform="matrix(1 0 0 -1 0 314)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#00FFA3" />
                <stop offset="1" stopColor="#DC1FFF" />
                </linearGradient>
                <linearGradient id="SVGID_2_" x1="264.829" y1="401.601" x2="45.163" y2="-19.147" gradientTransform="matrix(1 0 0 -1 0 314)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#00FFA3" />
                <stop offset="1" stopColor="#DC1FFF" />
                </linearGradient>
                <linearGradient id="SVGID_3_" x1="312.548" y1="376.688" x2="92.882" y2="-44.061" gradientTransform="matrix(1 0 0 -1 0 314)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stopColor="#00FFA3" />
                <stop offset="1" stopColor="#DC1FFF" />
                </linearGradient>
            </defs>
            <path fill="url(#SVGID_1_)" d="M64.6,237.9c2.4-2.4,5.7-3.8,9.2-3.8h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5c-5.8,0-8.7-7-4.6-11.1L64.6,237.9z" />
            <path fill="url(#SVGID_2_)" d="M64.6,3.8C67.1,1.4,70.4,0,73.8,0h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5c-5.8,0-8.7-7-4.6-11.1L64.6,3.8z" />
            <path fill="url(#SVGID_3_)" d="M333.1,120.1c-2.4-2.4-5.7-3.8-9.2-3.8H6.5c-5.8,0-8.7,7-4.6,11.1l62.7,62.7c2.4,2.4,5.7,3.8,9.2,3.8h317.4c5.8,0,8.7-7,4.6-11.1L333.1,120.1z" />
        </svg>
    );
}