import React, { useState, useEffect } from "react";
import { DeveloperContact } from "../../../common/DeveloperContact";

interface TableRowProps {
  osrsName: string;
  gp: number;
  solana: number;
  transaction_verified: boolean;
  transaction_completed: boolean;
  transaction_failed: boolean;
  signature: string;
  order_id: string; 
  onVerify: (order_id: string) => void; 
  onCallBot: (signature: string, order_id: string) => void; 
  systemReady: boolean;
}

const TableRow: React.FC<TableRowProps> = ({
  osrsName,
  gp,
  solana,
  transaction_verified,
  transaction_completed,
  transaction_failed,
  signature,
  order_id, 
  onVerify,
  onCallBot,
  systemReady,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const shortenSignature = (sig: string) => `${sig.slice(0, 2)}...${sig.slice(-3)}`;
  // const solscanLink = `https://solscan.io/tx/${signature}?cluster=devnet`;
  const solscanLink = `https://solscan.io/tx/${signature}`;


  const formatGoldValue = (gp: number) => {
    return (gp / 1000000).toFixed(2) + "/m";
  };

  const getStatusClass = () => {
    if (transaction_failed) {
      return 'badge badge-error';
    } else if (transaction_completed) {
      return 'badge badge-success';
    } else if (transaction_verified) {
      return 'badge badge-info';
    } else {
      return 'badge badge-warning';
    }
  };

  const getStatusText = () => {
    if (transaction_failed) {
      return 'error';
    } else if (transaction_completed) {
      return 'complete';
    } else if (transaction_verified) {
      return 'verified';
    } else {
      return 'unverified';
    }
  };

  const getButtonText = () => {

    if(transaction_completed){
      return "Complete";
    }

    if (transaction_verified && !transaction_completed && !transaction_failed) {
      return "Enter Queue";
    } else {
      return "Verify";
    }
  };

  const handleVerifyClick = () => {
    if (transaction_verified && !transaction_completed && !transaction_failed) {
      setIsModalOpen(true); 
    } else {
      onVerify(order_id);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); 
  };

  return (
    <>
      <tr>
        <td>{osrsName}</td>
        <td>{formatGoldValue(gp)}</td>
        <td>
          {solana.toFixed(4)} <span className="s-color font-bold">s</span><span className="o-color font-bold">o</span><span className="l-color font-bold">l</span>
        </td>
        <td>
          <div className={`badge gap-2 min-w-20 ${getStatusClass()}`}>
            {getStatusText()}
          </div>
        </td>
        <td>
          <a href={solscanLink} target="_blank" rel="noopener noreferrer">
            {shortenSignature(signature)}
          </a>
        </td>
        <td>
          <button
            className="btn bg-red-500 text-white mt-2 px-8 min-w-48"
            onClick={handleVerifyClick}
            disabled={!systemReady || transaction_completed || transaction_failed}
          >
            {getButtonText()}
          </button>
        </td>
      </tr>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg z-50">
          <article className="prose">
            <h1>Important!</h1>
            <ul>
              <li>
                Log into world 308 and be ready at Edgeville Bank.
              </li>
              <li>
                Wait for the agent to trade with you.
              </li> 
              <li>
                If you tamper with the agent, you will be banned with no refund.
              </li>
              <li>
                If something goes wrong, contact the creator, you will get a full refund.
              </li>
              <DeveloperContact/>
            </ul>
          </article>
            <div className="modal-action">
              <button className="btn bg-gray-500 text-white mr-2" onClick={handleCloseModal}>Cancel</button>
              <button className="btn bg-red-500 text-white" onClick={() => { onCallBot(signature, order_id); handleCloseModal(); }}>Enter Queue</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableRow;
