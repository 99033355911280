import React from "react";
import master_g00se from '../../common/images/master_g00se.jpg'
import osrs_gold from '../../common/images/osrs_gold.png'

export function BuiltBy() {
    return(
        <div className="flex flex-row items-center justify-center bg-base-200 py-12">
            <div className="stats shadow">
                <div className="stat">
                    <div className="flex flex-col">


                        <div className="flex flex-row items-center">
                            <GooseAvatar/>
                            <div className="prose p-2 pl-4">
                                <blockquote>Built by <a target="_blank" href="https://twitter.com/master_g00se">master_g00se</a></blockquote>
                            </div>
                            <OsrsGold/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const GooseAvatar = () => {
    return (
        <div className="avatar flex justify-center items-center pt-4">
            <div className="w-24 h-24 rounded-full ring-2 ring-red-500 ring-offset-2 ring-offset-white">
                <img src={master_g00se} alt="gnome_trader" className="w-full h-full object-cover rounded-full"/>
            </div>
        </div>
    );
}

const OsrsGold = () => {
    return(
        <img src={osrs_gold} alt="osrs_gold" className="w-[25px] h-[25px]"/>
    )
}
