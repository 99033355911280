export const getTotalGold = async () => {
    console.log('[get total gold] Getting total gold in pool...');
    
    const token = localStorage.getItem('jwt');
    
        if (!token) {
        console.error('[get total gold] No token found...');
        setTimeout(() => {
            window.location.reload();
            }, 4000);
        }
    
        const apiUrl = `/api/v1/gold/total/`;
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
    
        if(response.ok){
            console.log('[get total gold] Request OK!');
            const responseJson = await response.json();
            return responseJson;
        }
    
        if(!response.ok){
            console.log(`[get total gold] Request failed, status: ${response.status}`);
            return undefined;
        }
    
        } catch (error) {
            console.error('[get total gold] Failed to fetch price:', error);
        }
    };
    