export const createGoldBuyOrder = async (
    gp_value: string,
    sol_value: string,
    wallet_id: string,
    transaction_signature: string,
    recipient_name: string
) => {
    // console.log('[gold buy order] Requesting gold buy order...');
    // console.log('[gold buy order] gp_value: ', gp_value);
    // console.log('[gold buy order] sol_value: ', sol_value);
    // console.log('[gold buy order] wallet_id: ', wallet_id);
    // console.log('[gold buy order] transaction_signature: ', transaction_signature);
    // console.log('[gold buy order] recipient_name: ', recipient_name);

    const token = localStorage.getItem('jwt');

    if (!token) {
      console.error('[gold buy order] No token found...');
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }

    const apiUrl = `/api/v1/gold/buy/`;

    const requestBody = JSON.stringify({
        gp_value: parseFloat(gp_value),
        sol_value: parseFloat(sol_value),
        wallet_id: wallet_id,
        transaction_signature: transaction_signature,
        recipient_name: recipient_name,
    });

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: requestBody,
        });

        if (!response.ok) {
            console.log('[gold buy order] Network response was not ok, status: ', response.status);
            return false;
        }

        const responseData = await response.json();
        console.log('[gold buy order] Status: ' + response.status);

        if (response.status === 200) {
            console.log('[gold buy order] Request OK');
            return responseData;
        } else {
            console.log('[gold buy order] Request BAD');
            return responseData;
        }
    } catch (error) {
        console.error('[gold buy order] Failure', error);
        return false;
    }
};
