export const loginUser = async (wallet_id: string) => {
    console.log('[login user] Logging in user');
    const apiUrl = `/api/v1/user/login/`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({wallet_id: wallet_id})
      });
  
      if (response.status === 204) 
      {
        console.log('[login user] No user found');
        return undefined; 
      }
  
      if (!response.ok) {
        console.log(`[login user] Request failed, status: ${response.status}`);
        return undefined;
      }
  
      if(response.ok){
        console.log('[login user] Request OK');
        const responseJson = await response.json();
        return responseJson;
      }
  
    } catch (error) {
      console.error('[login user] Failed to login user:', error);
      return null;
    }
  };