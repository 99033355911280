import React, { useEffect, useState } from 'react';
import { Hero } from './landing_components/Hero';
import { Timeline } from './landing_components/Timeline';
import { Stats } from './landing_components/Stats';
import { BuiltBy } from './landing_components/BuiltBy';

// Define types for the data and error states
type OrderInfo = any; // Replace 'any' with the actual type of your order data
type ErrorType = { message: string };

export function LandingPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorType | null>(null);

  return (
    <>
      <div className="w-full overflow-y-auto">
        <Hero />
        <Stats />
        <Timeline />
        <BuiltBy />
      </div>
    </>
  );
}
