import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from "react";
import useCountdownTimer from "../../../utils/useCountdownTimer";

interface TerminalLine {
  prefix: string;
  text: string;
  className?: string;
}

export interface GoldBuyTerminalHandle {
  addLine: (line: TerminalLine) => void;
  updateLastLine: (text: string, className?: string) => void;
  addSpinnerToLastLine: () => void;
  removeSpinnerFromLastLine: () => void;
  startTimer: () => void;
  stopTimer: () => void;
  resetTimer: () => void;
}

export const GoldBuyTerminal = forwardRef<GoldBuyTerminalHandle>((_, ref) => {
  const [lines, setLines] = useState<TerminalLine[]>([]);
  const [spinnerIndex, setSpinnerIndex] = useState<number | null>(null);
  const linesRef = useRef<TerminalLine[]>([]);
  const spinnerChars = ["|", "/", "-", "\\"];
  const MAX_LINES = 17;

  const { time, startTimer, stopTimer, resetTimer } = useCountdownTimer(2, 0);

  useImperativeHandle(ref, () => ({
    addLine: (line: TerminalLine) => {
      linesRef.current = [...linesRef.current, line];
      if (linesRef.current.length > MAX_LINES) {
        linesRef.current.shift();
      }
      setLines([...linesRef.current]);
    },
    updateLastLine: (text: string, className?: string) => {
      const updatedLines = [...linesRef.current];
      const lastLine = updatedLines.pop();
      if (lastLine) {
        updatedLines.push({ ...lastLine, text, className: className || lastLine.className });
        linesRef.current = updatedLines;
        setLines(updatedLines);
      }
    },
    addSpinnerToLastLine: () => {
      if (linesRef.current.length > 0) {
        const lastLine = linesRef.current[linesRef.current.length - 1];
        lastLine.text += "  ";
        setSpinnerIndex(linesRef.current.length - 1);
      }
    },
    removeSpinnerFromLastLine: () => {
      setSpinnerIndex(null);
    },
    startTimer,
    stopTimer,
    resetTimer,
  }));

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (spinnerIndex !== null) {
      interval = setInterval(() => {
        setLines((prevLines) => {
          const newLines = [...prevLines];
          if (spinnerIndex !== null) {
            const spinnerLine = newLines[spinnerIndex];
            const nextChar = spinnerChars[(spinnerChars.indexOf(spinnerLine.text.slice(-1)) + 1) % spinnerChars.length];
            newLines[spinnerIndex] = { ...spinnerLine, text: spinnerLine.text.slice(0, -1) + nextChar };
          }
          return newLines;
        });
      }, 100);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [spinnerIndex]);

  const formatTime = (minutes: number, seconds: number) => {
    if (minutes === -1 && seconds === -1) {
      return "-:--";
    }
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className="flex-grow-0 flex-shrink-0">
      <div className="mockup-code flex flex-grow-0 flex-col min-h-[500px] max-h-[500px] justify-start overflow-auto">
        <Timer currentTime={formatTime(time.minutes, time.seconds)} />
        {lines.map((line, index) => (
          <pre key={index} data-prefix={line.prefix} className={line.className}>
            <code>{line.text}</code>
          </pre>
        ))}
      </div>
    </div>
  );
});

interface TimerProps {
  currentTime: string;
}

const Timer: React.FC<TimerProps> = ({ currentTime }) => {
  return (
    <div className="absolute top-4 right-4 text-white shadow-lg p-2">
      <div className="badge badge-info gap-2">
        Time Remaining: {currentTime}
      </div>
    </div>
  );
};
