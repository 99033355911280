export const getUser = async (walletId: string) => {
  console.log('[get user] Getting user');

  const token = localStorage.getItem('jwt');

  if (!token) {
    console.error('[get user] No token found...');
    setTimeout(() => {
      window.location.reload();
    }, 4000);
  }

  const apiUrl = `/api/v1/user/${walletId}/`;
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    });

    if (response.status === 204) 
    {
      console.log('[get user] No user found');
      return undefined; 
    }

    if (!response.ok) {
      console.log(`[get user] Request failed, status: ${response.status}`);
      return undefined;
    }

    if(response.ok){
      console.log('[get user] Request OK');
      const responseJson = await response.json();
      return responseJson;
    }

  } catch (error) {
    console.error('[get user] Failed to fetch user:', error);
    return undefined;
  }
};