export const getSolanaPrice = async () => {
console.log('[get solana price] Getting solana price...');

const token = localStorage.getItem('jwt');

    if (!token) {
    console.error('[get solana price] No token found...');
    setTimeout(() => {
        window.location.reload();
        }, 4000);
    }

    const apiUrl = `/api/v1/solana/price/`;
    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

    if(response.ok){
        console.log('[get solana price] Request OK!');
        const responseJson = await response.json();
        return responseJson;
    }

    if(!response.ok){
        console.log(`[get solana price] Request failed, status: ${response.status}`);
        return undefined;
    }

    } catch (error) {
        console.error('[get solana price] Failed to fetch price:', error);
    }
};
