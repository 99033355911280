import { PhantomProvider } from "../types";

export const getProvider = (): PhantomProvider | undefined => {
    if('phantom' in window) {
        const anyWindow: any = window;
        const provider = anyWindow.phantom?.solana;

        if(provider?.isPhantom) {
            return provider;
        }
    }
}