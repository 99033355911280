import React, { useEffect, useState } from 'react';
import { getTotalGoldSold } from './LiveSales/getTotalGoldSold';
import { DiscordLink } from './DiscordLink';

export function Stats(){

    const [totalGold, setTotalGold] = useState<number | null>(null);

    useEffect(() => {

        const fetchTotalGold = async () => {
            const totalGold = await getTotalGoldSold();
            setTotalGold(totalGold.total_gold_sold);
        }

        fetchTotalGold();
    }, []);


    return(
        <>  
            <div className='flex items-center justify-center p-2 pt-4 pb-4 bg-base-200'>     
                <div className="stats stats-vertical lg:stats-horizontal shadow border-2 border-red-500">                
                    <div className="stat">
                        <div className="stat-title font-mono"><b>Total Gold Sold</b></div>
                        <div className="stat-value">{totalGold !== null ? totalGold : 0}</div>
                    </div>
                    
                    <div className="stat">
                        <div className="stat-title font-mono"><b>Total Items Sold</b></div>
                        <div className="stat-value">{0}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-title font-mono"><b>Total Accounts Sold</b></div>
                        <div className="stat-value">{0}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-title font-mono"><b>Total Scripts Sold</b></div>
                        <div className="stat-value">{0}</div>
                    </div>
                
                </div>
            </div>
        <DiscordLink />

            <div className="bg-base-200 flex items-center justify-center ">
                <div className="prose p-8">
                    <p><b>gnome_trader</b> runs on the Solana Chain to provide old school runescape assets trading including:</p>
                    <ul>
                        <li>Gold Buying</li>
                        <li>Item Buying</li>
                        <li>Account Buying</li>
                        <li>Leveling Services</li>
                        <li>Scripting Services</li>
                    </ul>
                    <blockquote>We are trying to achieve total automation of each process via botting api's, web api's and crypto transactions</blockquote>
                </div>
            </div>  
        </>
    );
}