import React, { useState, useEffect } from "react";
import { getSolanaPrice } from "../../../utils/solana/getSolanaPrice";
import { createGoldBuyOrder } from "../../../utils/gold/createGoldBuyOrder";
import { getGoldBuyOrders } from "../../../utils/gold/getGoldBuyOrders";
import { useGeneral } from "../../general_context/GeneralContext";
import { getSerializedTx } from "../../../utils/solana/getSerializedTx";
import { useWebSocket } from "../../websocket_context/WebsocketContext";
import { getProvider } from "../../../utils/getProvider";
import { getMaxBuyAmount } from "../../../utils/gold/getMaxBuyAmount";
import { getGoldPrice } from "../../../utils/gold/getGoldPrice";
import { getTotalGold } from "../../../utils/gold/getTotalGold";
import { getTotalReservedGold } from "../../../utils/gold/getTotalReservedGold";

import GoldBuyMenu from "./GoldBuyMenu";
import GoldBuyList from "./GoldBuyList";

export const GoldMenu: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [goldPricePerMill, setGoldPrice] = useState<number | null>(0.2);
  const [solPrice, setSolPrice] = useState<number | null>(null);
  const [inputSolana, setInputSolana] = useState<string>("");
  const [inputGold, setInputGold] = useState<string>("0.00/m");
  const [purchaseRequested, setPurchaseRequested] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [goldBuyOrders, setGoldBuyOrders] = useState<any[]>([]);
  const [lastUpdate, setLastUpdate] = useState<number>(Date.now());
  const [systemReady, setSystemReady] = useState<boolean>(false);
  const [maximumOrder, setMaximumOrder] = useState<number>(100000000);
  const minimumOrder: number = 0.1;
  const [totalGold, setTotalGold] = useState<number>(0);
  const [totalReservedGold, setTotalReservedGold] = useState<number>(0);
  const [totalPoolSize, setTotalPoolSize] = useState<number>(0);

  const { walletId } = useGeneral();

  const { messages } = useWebSocket();

  useEffect(() => {
    if (messages.length === 0) return;

    const latestMessage = messages[messages.length - 1];
    const parsedMessage = JSON.parse(latestMessage);
    const { op_code, data } = parsedMessage;

    switch (op_code) {
      case 4: //gold pool update
      const lastTotalReservedGold = totalReservedGold;
      setTotalReservedGold(lastTotalReservedGold + data);
      break;
    }
  }, [messages]);


  useEffect(() => {
    setTotalPoolSize(totalGold - totalReservedGold);
  }, [totalReservedGold, totalGold]);


  useEffect(() => {
    if (messages.length === 0) return;
  
    const latestMessage = messages[messages.length - 1];
    const parsedMessage = JSON.parse(latestMessage);
    const { op_code, data } = parsedMessage;
  
    switch (op_code) {
      case 3:
        setSolPrice(data.solana_price);
        setLastUpdate(Date.now());
    }
  }, [messages]);

  useEffect(() => {
    const fetchSolanaPrice = async () => {
      const priceInfo = await getSolanaPrice();
      setSolPrice(priceInfo.solana_price);
      setLastUpdate(Date.now());
      setLoading(false);
    };

    const grabBuyOrders = async () => {
      var buy_orders = await getGoldBuyOrders(walletId);
      setGoldBuyOrders(buy_orders);
    }

    const fetchGoldPrice = async () => {
      var goldPrice = await getGoldPrice();
      setGoldPrice(goldPrice);
    }
    
    const grabTotalGold = async () => {
      const totalGold = await getTotalGold();
      setTotalGold(totalGold);
      console.log(totalGold);
    }

    const grabTotalReservedGold = async () => {
      const totalReservedGold = await getTotalReservedGold();
      setTotalReservedGold(totalReservedGold);
      console.log(totalReservedGold);
    }

    // const maximumBuyAmount = async () => {
    //   const maxBuyInfo = await getMaxBuyAmount();
    //   console.log(maxBuyInfo.max_buy_amount);
    //   setMaximumOrder(maxBuyInfo.max_buy_amount);
    // }

    fetchSolanaPrice();
    grabBuyOrders();
    fetchGoldPrice();
    grabTotalGold();
    grabTotalReservedGold();
    // maximumBuyAmount();
  }, [walletId]);

  const renderDollarValue = () => {
    if (solPrice) {
      const solanaAmount = parseFloat(inputSolana);
      if (isNaN(solanaAmount)) {
        return "0.00";
      }
      const dollarValue = solanaAmount * solPrice;
      return dollarValue.toFixed(2);
    }
    return "0.00";
  };

  const handleSolanaInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputSolana(value);
    updateGoldValue(value);
  };

  const handleUsernameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const updateGoldValue = (solanaValue: string) => {
    if (solPrice && goldPricePerMill) {
      const solanaAmount = parseFloat(solanaValue);
      if (isNaN(solanaAmount)) {
        setInputGold("0.00/m");
        return;
      }
      // dollar value = solana amount * solana dollar value
      const dollarValue = solanaAmount * solPrice;
      const goldValue = dollarValue / goldPricePerMill;
      setInputGold(goldValue.toFixed(2) + "/m");
    } else {
      setInputGold("0.00/m");
    }
  };

  const handlePurchaseRequest = async () => {
    setSystemReady(false);
    setPurchaseRequested(true);
    try {
      const provider = getProvider();
      if (!provider) {
        console.error("Provider not found");
        setPurchaseRequested(false);
        return;
      }

      await provider.connect();
   
      const fromPubkey: any = provider.publicKey;
      const lamports = parseFloat(inputSolana);

      const generated_tx = await getSerializedTx(fromPubkey, lamports);

      const signedTransaction = await provider.signTransaction(generated_tx);

      const serializedTx = signedTransaction.serialize().toString('base64');

      executeBuyRoutine(fromPubkey, serializedTx);

    } catch (error) {
      console.error("Transaction failed", error);
    } finally {
      setPurchaseRequested(false);
    }
  };

  const executeBuyRoutine = (fromPubkey: string, signature: string) => {
    createGoldBuyOrder(inputGold, inputSolana, fromPubkey, signature, username);
  };

  if (loading) {
    return <span className="loading loading-spinner text-warning"></span>;
  } else {
    return (
      <div className="flex space-x-4">
        <div className="">
          <GoldBuyMenu
            solPrice={solPrice}
            goldPricePerMill={goldPricePerMill}
            inputSolana={inputSolana}
            inputGold={inputGold}
            purchaseRequested={purchaseRequested}
            username={username}
            handleSolanaInput={handleSolanaInput}
            handleUsernameInput={handleUsernameInput}
            renderDollarValue={renderDollarValue}
            handlePurchaseRequest={handlePurchaseRequest}
            lastUpdate={lastUpdate}
            systemReady={systemReady}
            setSystemReady={setSystemReady}
            minimumOrder={minimumOrder}
            maximumOrder={maximumOrder}
            totalPoolSize={totalPoolSize}
          />
        </div>
        <GoldBuyList initialGoldBuyOrders={goldBuyOrders} systemReady={systemReady} setSystemReady={setSystemReady} />
      </div>
    );
  }
};
 