import React, { useState } from 'react';
import { TabsMenu } from './TabsMenu';
import { GoldMenu } from './gold_menu/GoldMenu';


export const MainMenu: React.FC = () => {
    const [activeTab, setActiveTab] = useState('Gold');

    const renderMenu = () => {
        switch (activeTab) {
            case 'Gold':
                return <GoldMenu/>;
            case 'Items':
                return <>Not Done!</>;
            case 'Accounts':
                return <>Not Done!</>;
            default:
                return <div>No tab selected!</div>;
        }
    };

    return (
        <div className="flex flex-col">
            <TabsMenu activeTab={activeTab} setActiveTab={setActiveTab} />
            <div className="flex-grow flex items-center justify-center w-full h-full p-8">
                {renderMenu()}
            </div>
        </div>
    );
};
