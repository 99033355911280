export const getGoldPrice = async () => {
    console.log('[get gold price] Getting gold price...');
    
    const token = localStorage.getItem('jwt');
    
        if (!token) {
        console.error('[get gold price] No token found...');
        setTimeout(() => {
            window.location.reload();
            }, 4000);
        }
    
        const apiUrl = `/api/v1/gold/price/`;
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });
    
        if(response.ok){
            console.log('[get gold price] Request OK!');
            const responseJson = await response.json();
            return responseJson;
        }
    
        if(!response.ok){
            console.log(`[get gold price] Request failed, status: ${response.status}`);
            return undefined;
        }
    
        } catch (error) {
            console.error('[get gold price] Failed to fetch price:', error);
        }
    };
    