import React, { useRef, useEffect, useState } from "react";
import osrs_gold from "../../../common/images/osrs_gold.png";
import { SolanaSVG } from "../../../common/svgs/SolanaSVG";
import TableRow from "./TableRow"; // Adjust the import path as necessary
import { GoldBuyTerminal, GoldBuyTerminalHandle } from "./GoldBuyTerminal";
import { useWebSocket } from "../../websocket_context/WebsocketContext";
import { manualVerifyTransaction } from "../../../utils/gold/manualVerifyTransaction";
import { addOrderToPool } from "../../../utils/gold/addOrderToPool";

const OsrsGold = () => {
  return <img src={osrs_gold} alt="osrs_gold" className="w-[25px] h-[25px]" />;
};

interface GoldBuyListProps {
  initialGoldBuyOrders: any[];
  systemReady: boolean;
  setSystemReady: (ready: boolean) => void;
}

const GoldBuyList: React.FC<GoldBuyListProps> = ({ initialGoldBuyOrders, systemReady, setSystemReady }) => {
  const [goldBuyOrders, setGoldBuyOrders] = useState<any[]>([]);
  const { messages, openWebSocket, closeWebSocket, isConnected } = useWebSocket();
  const [loading, setLoading] = useState<boolean>(true);
  const [searching, setSearching] = useState<boolean>(false);
  const [currentOrder, setCurrentOrder] = useState<string | null>(null);
  const terminalRef = useRef<GoldBuyTerminalHandle>(null);
  const searchIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const shortenSignature = (sig: string) => `${sig.slice(0, 2)}...${sig.slice(-3)}`;

  useEffect(() => {
    if(!loading){
      setSystemReady(true);
    }
  }, [loading]);

  useEffect(() => {
    if (initialGoldBuyOrders) {
      setGoldBuyOrders(initialGoldBuyOrders.slice().reverse());
    }
  }, [initialGoldBuyOrders]);

  useEffect(() => {
    if (searching) {
      searchIntervalRef.current = setInterval(() => {
        removeSpinnerFromLastLine();
        addLineToTerminal(">", "Searching for agent in swarm... ");
        addSpinnerToLastLine();
      }, 10000);
    } else {
      if (searchIntervalRef.current) {
        clearInterval(searchIntervalRef.current);
        searchIntervalRef.current = null;
      }
      removeSpinnerFromLastLine();
    }

    return () => {
      if (searchIntervalRef.current) {
        clearInterval(searchIntervalRef.current);
      }
    };
  }, [searching]);

  const addLineToTerminal = (_prefix: string, _text: string) => {
    if (terminalRef.current) {
      terminalRef.current.addLine({ prefix: _prefix, text: _text });
    }
  };

  const stopSpinner = () => {
    if (terminalRef.current) {
      terminalRef.current.removeSpinnerFromLastLine();
      // terminalRef.current.addLine({ prefix: "$", text: "Done!" });
    }
  };

  const addSpinnerToLastLine = () => {
    if (terminalRef.current) {
      terminalRef.current.addSpinnerToLastLine();
    }
  };

  const removeSpinnerFromLastLine = () => {
    if (terminalRef.current) {
      terminalRef.current.removeSpinnerFromLastLine();
    }
  };

  const startTimer = () => {
    if(terminalRef.current){
      terminalRef.current.startTimer();
    }
  }

  const resetTimer = () => {
    if(terminalRef.current){
      terminalRef.current.resetTimer();
    }
  }

  const stopTimer = () => {
    if(terminalRef.current){
      terminalRef.current.stopTimer();
    }
  }

  const updateLastLine = (text: string, className?: string) => {
    if (terminalRef.current) {
      terminalRef.current.updateLastLine(text, className);
    }
  };

  const handleVerifyTransaction = async (order_id: string) => {
    setSystemReady(false);

    addLineToTerminal(">", `Attempting to verify transaction ${order_id}...`);
    addSpinnerToLastLine();

    const response = await manualVerifyTransaction(order_id);
    
    if (response) {
      removeSpinnerFromLastLine();
      addLineToTerminal(">", `Transaction ${order_id} verification initiated successfully `);
      addSpinnerToLastLine();

    } else {
      updateLastLine(`Transaction ${order_id} verification initiation failed.`, "text-red-500");
      removeSpinnerFromLastLine();
    }
  };

  const handleCallBot = async (order_sig: string, order_id: string) => {
    setSystemReady(false);
    setCurrentOrder(order_id);
    const pool_info = await addOrderToPool(order_id);

    if(pool_info) {

      if(pool_info.in_pool === true){
        addLineToTerminal(">", `Starting delivery for ${shortenSignature(order_sig)}`);
        addLineToTerminal(">", `Searching for agent in swarm...`);
        addSpinnerToLastLine();
        setSearching(true);
      } else if (pool_info.in_pool == false) {


        //Bodged but will have 2 do for now
        addLineToTerminal("X", "Not enough gold in the pool, please try again later or contact master_g00se...");
        updateLastLine("Not enough gold in the pool, please try again later or contact master_g00se...", "text-red-500");
        setSystemReady(true);
        setCurrentOrder(null);
      }
      
    } else {
      console.log('[gold buy list] Not added to pool');
    }
  };

  useEffect(() => {
    if (terminalRef.current && terminalRef.current != null) {
      setTimeout(() => {
        addLineToTerminal("$", "connect gnome_trader");
        addSpinnerToLastLine();
        setTimeout(() => {
          openWebSocket();
        }, 2000);
      }, 0);
    }
  }, [terminalRef]);

  useEffect(() => {
    if (isConnected) {
      removeSpinnerFromLastLine();
      updateLastLine("Connected to gnome_trader!", "text-green-500");
      setLoading(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (messages.length === 0) return;

    const latestMessage = messages[messages.length - 1];
    const parsedMessage = JSON.parse(latestMessage);
    const { op_code, data } = parsedMessage;

    switch (op_code) {
      case 0: // Terminal update from bot

        if(searching){
          setSearching(false);
        }


        if(data.terminal_update.includes("You have 2 minutes from now to receive your gold!")){
          startTimer();
        }
        
        if(data.terminal_update.includes("Delivery complete")){
          removeSpinnerFromLastLine();
          addLineToTerminal("$", data.terminal_update);
          updateLastLine("Delivery complete!", "text-green-500");
          stopSpinner();
          setSystemReady(true);
          stopTimer();
          resetTimer();
          

          setGoldBuyOrders((prevOrders) =>
            prevOrders.map((order) =>
              order.order_id === currentOrder ? { ...order, transaction_completed: true } : order
            )
          );

          setCurrentOrder(null);

        } else if(data.terminal_update.includes("ERROR")) {
          removeSpinnerFromLastLine();
          addLineToTerminal("X", data.terminal_update);
          updateLastLine("ERROR: You have taken too long, an admin will investigate within 1 day!", "text-red-500");
          stopSpinner();
          setSystemReady(true);
          stopTimer();
          resetTimer();

          setGoldBuyOrders((prevOrders) =>
            prevOrders.map((order) =>
              order.order_id === currentOrder ? { ...order, transaction_failed: true } : order
            )
          );

        } else {
          removeSpinnerFromLastLine();
          addLineToTerminal(">", data.terminal_update);
          addSpinnerToLastLine();
        }

        break;
      case 1: // Buy order created
        console.log('[websocket] Buy order created!', data);
        addLineToTerminal(">", `Buy order created for ${data.buy_order.recipient_name}`);
        addLineToTerminal(">", `Verifying buy order...`);
        addSpinnerToLastLine();
        setGoldBuyOrders((prevOrders) => {
          const newOrders = prevOrders.filter(order => order.order_id !== data.buy_order.order_id);
          return [data.buy_order, ...newOrders]; // Ensure new orders are added at the beginning
        });
        break;
      case 2: // Transaction update
        console.log('[websocket] Transaction update', data);
        removeSpinnerFromLastLine();
        if (data.verification_info.verification) {
          addLineToTerminal(">", `Transaction verified, click "Enter Queue" to get your gold!`);
          addLineToTerminal(">", `!!!IMPORTANT!!!: Make sure you are on world 308 inside Edgeville Bank!`);

        } else {
          addLineToTerminal(">", `Transaction not yet verified, click verify to try again.`);
          addLineToTerminal(">", `Its likely the transaction was never sent, check your wallet and signature.`);
        }
        setGoldBuyOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.order_id === data.verification_info.order_id
              ? { ...order, transaction_verified: data.verification_info.verification}
              : order
          )
        );
        setSystemReady(true);
        break;
    }
  }, [messages]);

  return (
    <div className="bg-white border-2 rounded-lg border-red-500 p-2 flex flex-col max-h-[900px] min-h-[900px] min-w-[880px]">
      <div className="flex-1 overflow-y-auto mb-2">
        <span className="font-bold text-lg">Purchases</span>
        <div className="mt-4">
          <div className="overflow-x-hidden">
            <table className="table">
              <thead>
                <tr>
                  <th>OSRS Name</th>
                  <th>
                    <div className="flex justify-start items-center">
                      <OsrsGold /><span className="pl-2">gp/m</span>
                    </div>
                  </th>
                  <th>
                    <div className="flex justify-start items-center">
                      <SolanaSVG /><span className="pl-2">Solana</span>
                    </div>
                  </th>
                  <th>Status</th>
                  <th>Signature</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!loading && goldBuyOrders.map((order) => (
                  <TableRow
                    key={order.transaction_signature}
                    osrsName={order.recipient_name}
                    gp={order.gp_value}
                    solana={order.sol_value}
                    transaction_verified={order.transaction_verified}
                    transaction_completed={order.transaction_completed}
                    transaction_failed={order.transaction_failed}
                    signature={order.transaction_signature}
                    order_id={order.order_id}
                    onVerify={handleVerifyTransaction}
                    onCallBot={handleCallBot}
                    systemReady={systemReady}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <GoldBuyTerminal ref={terminalRef} />
    </div>
  );
};

export default GoldBuyList;
