import { Transaction, Message } from "@solana/web3.js";

interface TransactionRequest {
    from_pubkey: string;
    lamports: number;
}

export const getSerializedTx = async (
    from_pubkey: string,
    lamports: number
): Promise<Transaction> => {
    const token = localStorage.getItem('jwt');

    if (!token) {
        throw new Error('No JWT token found');
    }

    const apiUrl = `/api/v1/solana/generate-transaction/`;

    const requestBody: TransactionRequest = {
        from_pubkey,
        lamports
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch transaction: ${response.statusText}`);
        }

        const responseData = await response.json();

        const serializedMessage = Buffer.from(responseData.serialized_message, 'base64');
        const message = Message.from(serializedMessage);
        const transaction = Transaction.populate(message, []);

        return transaction;
    } catch (error) {
        console.error('Error fetching transaction:', error);
        throw error;
    }
};
