import React from 'react';
import { RunescapeItem } from '../../../types';

function GPtoSOL(value: number): number {
    const poundsPerMillionGP = 0.20;
    const solPerPound = 0.008;  // Updated from 0.0016 to 0.008
    const solPerMillionGP = poundsPerMillionGP * solPerPound;
    return value / 1_000_000 * solPerMillionGP;
}

export function Sale({ saleInfo }: any) {

    // let displayContent;
    // if (saleInfo.type === 'gold') {
    //     // displayContent = `${saleInfo.sale_info.gp_value}m gp`;
    //     displayContent = <span>{saleInfo.sale_info.gp_value}m <span className="text-yellow-400">gp</span></span>
    // } else {
    //     displayContent = `${saleInfo.sale_info.item_name}`;
    // }

    let displayContent = <span>{saleInfo.gp_price / 1000000}m <span className="text-yellow-400">gp</span></span>


    const solValue = GPtoSOL(saleInfo.gp_price);
    const formattedSolValue = solValue.toFixed(3);

    return (
        <pre data-prefix=">">
            <code>s: {formattedSolValue} <Sol/> / {displayContent} </code>
        </pre>
    );
}

function Sol (){
    return(
        <>
            <span className="s-color">s</span>
            <span className="o-color">o</span>
            <span className="l-color">l</span>
        </>
    )
}