import { useState, useEffect, useRef, useCallback } from 'react';

const useCountdownTimer = (initialMinutes = 2, initialSeconds = 0) => {
  const [time, setTime] = useState({ minutes: -1, seconds: -1 });
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const tick = useCallback(() => {
    setTime((prevTime) => {
      let { minutes, seconds } = prevTime;

      if (minutes === 0 && seconds === 0) {
        if (intervalRef.current) clearInterval(intervalRef.current);
        return { minutes: 0, seconds: 0 };
      }

      if (seconds === 0) {
        minutes -= 1;
        seconds = 59;
      } else {
        seconds -= 1;
      }

      return { minutes, seconds };
    });
  }, []);

  const startTimer = useCallback(() => {
    setTime({ minutes: initialMinutes, seconds: initialSeconds });
    if (intervalRef.current) return; // prevent multiple intervals
    intervalRef.current = setInterval(tick, 1000);
  }, [initialMinutes, initialSeconds, tick]);

  const stopTimer = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const resetTimer = useCallback(() => {
    stopTimer();
    setTime({ minutes: -1, seconds: -1 });
  }, [stopTimer]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return { time, startTimer, stopTimer, resetTimer };
};

export default useCountdownTimer;
