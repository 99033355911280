import React, { useEffect, useState } from "react";
import { SolanaSVG } from "../../../common/svgs/SolanaSVG";
import osrs_gold from "../../../common/images/osrs_gold.png";
import { DeveloperContact } from "../../../common/DeveloperContact";

const OsrsGold = () => {
  return <img src={osrs_gold} alt="osrs_gold" className="w-[25px] h-[25px]" />;
};

interface GoldBuyMenuProps {
  solPrice: number | null;
  goldPricePerMill: number | null;
  inputSolana: string;
  inputGold: string;
  purchaseRequested: boolean;
  username: string;
  handleSolanaInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUsernameInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderDollarValue: () => string;
  handlePurchaseRequest: () => void;
  lastUpdate: number;
  systemReady: boolean;
  setSystemReady: (ready: boolean) => void;
  minimumOrder: number;
  maximumOrder: number;
  totalPoolSize: number;
}

const GoldBuyMenu: React.FC<GoldBuyMenuProps> = ({
  solPrice,
  goldPricePerMill,
  inputSolana,
  inputGold,
  purchaseRequested,
  username,
  handleSolanaInput,
  handleUsernameInput,
  renderDollarValue,
  handlePurchaseRequest,
  lastUpdate,
  systemReady,
  setSystemReady,
  minimumOrder,
  maximumOrder,
  totalPoolSize
}) => {
  const [progress, setProgress] = useState(100);
  const [fadeIn, setFadeIn] = useState(false);
  const [validOrder, setValidOrder] = useState(false);
  const [validUsername, setValidUsername] = useState(false);

  useEffect(() => {
    const inputGoldNum = parseFloat(inputGold);
    const inputSolanaNum = parseFloat(inputSolana);

    if (inputGoldNum >= minimumOrder && 
        inputGoldNum <= maximumOrder / 1000000 && 
        inputSolanaNum > 0 && 
        inputGoldNum <= totalPoolSize / 1000000) {
      setValidOrder(true);
    } else {
      setValidOrder(false);
    }

    if(username.length > 0) {
      setValidUsername(true);
    } else {
      setValidUsername(false);
    }


    }, [inputSolana, inputGold, username, totalPoolSize]);

  useEffect(() => {
    // Reset progress whenever lastUpdate is updated
    setProgress(100);

    // Trigger fade-in animation
    setFadeIn(true);
    const timeout = setTimeout(() => setFadeIn(false), 500); // Remove fade-in class after animation

    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress > 0 ? prevProgress - 3.33 : 100)); // Decrease progress every second, reset to 100 after 30 seconds
    }, 1000); // Update every second

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [lastUpdate]); // Add lastUpdate as a dependency to reset the progress bar

  return (
    <div>
      <div className="bg-white border-2 rounded-lg border-red-500 p-2">
        <span className="font-bold text-lg">Sell</span>
        <label className="input input-bordered flex items-center gap-2 mt-1">
          <SolanaSVG />
          <input
            type="text"
            className="grow"
            placeholder="0.0000"
            value={inputSolana}
            onChange={handleSolanaInput}
          />
        </label>
        <div className="flex flex-col">
          <div className="badge badge-warning gap-2 mt-2">
            $ {renderDollarValue()}
          </div>
          <div className={`badge badge-info gap-2 mt-2 ${fadeIn ? 'fade-in' : ''} `}>
            1 SOL = ${solPrice}
          </div>
          <div className="mt-2">
            <progress className="progress w-32 h-2" value={progress} max="100"></progress>
          </div>
        </div>
        
        <hr className="m-4" />
        <span className="font-bold text-lg">Buy</span>
        <label className="input input-bordered flex items-center gap-2 mt-1">
          <OsrsGold />
          <input
            type="text"
            className="grow"
            placeholder="0.0000"
            value={inputGold}
            readOnly
          />
        </label>
        <div className="flex flex-col">    
          <div className="badge badge-error gap-2 mt-2">
            gp/m: ${goldPricePerMill}
          </div>
          <div className="badge badge-warning gap-2 mt-2">
            Min Order: {minimumOrder}/m gp
          </div>
          <div className="badge badge-warning gap-2 mt-2">
            Max Order: {maximumOrder / 1000000}/m gp
          </div>
          <div className="badge badge-warning gap-2 mt-2">
            Total avaliable: {(totalPoolSize / 1000000).toFixed(2)}/m gp
          </div>
        </div>
        <hr className="m-4" />

        <div className="mt-4">
          <span className="font-bold text-lg">OSRS Account Name</span>
        </div>
        <label className="input input-bordered flex items-center gap-2 mt-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.735 14c.618 0 1.093-.561.872-1.139a6.002 6.002 0 0 0-11.215 0c-.22.578.254 1.139.872 1.139h9.47Z" />
          </svg>
          <input
            type="text"
            className="grow"
            placeholder="Account Name (Case Sensitive)"
            onChange={handleUsernameInput}
          />
        </label>

        <div className="flex flex-col items-center">
          {purchaseRequested ? (
            <button className="btn mt-2" disabled>
              <span className="loading loading-spinner"></span>
              Buying...
            </button>
          ) : (
            <button
              className="btn bg-red-500 text-white mt-2 px-8"
              onClick={handlePurchaseRequest}
              disabled={!systemReady || !validOrder || !validUsername}
            >
              Buy
            </button>
          )}
        </div>
      </div>
      <div className="pt-4">
        <DeveloperContact />
      </div>
    </div>
  );
};

export default GoldBuyMenu;
