import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the WebSocket context
interface WebSocketContextType {
  messages: string[];
  openWebSocket: () => void;
  closeWebSocket: () => void;
  isConnected: boolean; // Add connection status
}

// Create the context with an initial value of null
const WebSocketContext = createContext<WebSocketContextType | null>(null);

interface WebSocketProviderProps {
  children: ReactNode;
}

// WebSocketProvider component
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false); 

  const openWebSocket = () => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      console.error('[ws] No token found...');
      setTimeout(() => {
        window.location.reload();
      }, 4000);
      return;
    }
    // const websocket = new WebSocket(`ws://localhost:8080/v1/ws/?token=${token}`);
    const websocket = new WebSocket(`wss://gnome-trader-api-7d4b87970b3f.herokuapp.com/v1/ws/?token=${token}`);
    
    

    websocket.onopen = () => {
      console.log('[websocket] WebSocket connection opened');
      setIsConnected(true);
    };
    
    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const { op_code, data } = message;

      setMessages((prevMessages) => [...prevMessages, JSON.stringify(message)]);
    };
    
    websocket.onclose = () => {
      console.log('[websocket] WebSocket connection closed');
      setIsConnected(false);
    };

    websocket.onerror = (error) => {
      console.error('[websocket] WebSocket error:', error);
    }

    setWs(websocket);
  };

  const closeWebSocket = () => {
    if (ws) {
      ws.close();
      setWs(null);
      console.log('[websocket] WebSocket connection closed manually');
      setIsConnected(false); // Set connection status to false
    }
  };

  return (
    <WebSocketContext.Provider value={{ messages, openWebSocket, closeWebSocket, isConnected }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to use the WebSocket context
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('[websocket] useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
