import React from 'react';

export function Timeline(){
    return(

        <>
            <div className="p-6 bg-base-200 items-center justify-center">
                <div className="w-full flex justify-center">
                    <div className="prose pb-6">
                        <h1>Roadmap</h1>
                    </div>
                </div>

                <ul className="timeline timeline-snap-icon max-md:timeline-compact timeline-vertical">
                    <li>
                        <div className="timeline-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                        </div>
                        <div className="timeline-start md:text-end mb-10 max-w-[400px]">
                            <time className="font-mono italic bg-warning">&#40;Done/Refining&#41; Phase 1</time>
                            <div className="text-lg font-black">Gold Buying</div>
                            Fully automated gold buying, a bot agent will go to a meeting point and detect when the buyer is in the area.
                        </div>
                        <hr/>
                    </li>
                    <li>
                        <hr />
                        <div className="timeline-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                        </div>
                        <div className="timeline-end mb-10 max-w-[400px]">
                            <time className="font-mono italic bg-gray-600">Phase 2</time>
                            <div className="text-lg font-black">Item Buying</div>
                            Fully automated item buying, a bot agent will go to a meeting point and detect when the buyer is in the area.
                        </div>
                        <hr />
                    </li>
                    <li>
                        <hr />
                        <div className="timeline-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                        </div>
                        <div className="timeline-start md:text-end mb-10 max-w-[400px]">
                            <time className="font-mono italic bg-gray-600">Phase 3</time>
                            <div className="text-lg font-black">Account Buying</div>
                            Account details will be stored in escrow and an automated system will verify the existence of the account upon posting and upon purchasing.
                        </div>
                        <hr />
                    </li>
                    <li>
                        <hr />
                            <div className="timeline-middle">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                            </div>
                            <div className="timeline-end mb-10 max-w-[400px]">
                                <time className="font-mono italic bg-gray-600">Phase 4</time>
                                <div className="text-lg font-black">Leveling Services</div>
                                Upload account details and leveling requirements and wait for someone to accept the contract.
                            </div>
                        <hr />
                    </li>
                    <li>
                        <hr />
                        <div className="timeline-middle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" /></svg>
                        </div>
                        <div className="timeline-start md:text-end mb-10 max-w-[400px]">
                            <time className="font-mono italic bg-gray-600">Phase 5</time>
                            <div className="text-lg font-black">Scripting Services</div>
                            Post the script requirements and wait for a scripter to accept the contract.
                        </div>
                    </li>
                </ul>
            </div>
        </>
    );
}