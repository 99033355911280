export const addOrderToPool = async (
    order_id: string
) => {


    const token = localStorage.getItem('jwt');

    if (!token) {
      console.error('[add order to pool] No token found...');
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }

    const apiUrl = `/api/v1/gold/add_to_pool/`;

    const requestBody = JSON.stringify({
        order_id: order_id,
    });

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: requestBody,
        });

        if (!response.ok) {
            console.log('[add order to pool] Network response was not ok, status: ', response.status);
            return false;
        }

        const responseData = await response.json();
        console.log('[add order to pool] Status: ' + response.status);

        if (response.status === 200) {
            console.log('[add order to pool] Request OK');
            return responseData;
        } else {
            console.log('[add order to pool] Request BAD');
            return false;
        }
    } catch (error) {
        console.error('[add order to pool] Failure', error);
        return false;
    }
};
