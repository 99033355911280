import React from "react";
import master_g00se from './images/master_g00se.jpg'
import osrs_gold from './images/osrs_gold.png'

export function DeveloperContact() {
    return(
        <div className="flex flex-row items-center justify-center">
            <div className="stats shadow">
                <div className="stat">
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center">
                            <GooseAvatar/>
                            <div className="prose p-2 pl-4">
                                {/* <blockquote>Contact <a target="_blank" href="https://twitter.com/master_g00se">master_g00se</a></blockquote> */}
                                <blockquote>Contact <a target="_blank" href="https://discord.gg/AnHettnTRH">master_g00se</a></blockquote>
                            </div>
                            <OsrsGold/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const GooseAvatar = () => {
    return (
        <div className="avatar flex justify-center items-center">
            <div className="w-24 h-24 rounded-full ring-2 ring-red-500 ring-offset-2 ring-offset-white overflow-hidden flex justify-center items-center m-0 p-0">
                <img src={master_g00se} alt="gnome_trader" className="w-full h-full object-cover m-0 p-0"/>
            </div>
        </div>
    );
}

export default GooseAvatar;


const OsrsGold = () => {
    return(
        <img src={osrs_gold} alt="osrs_gold" className="w-[25px] h-[25px]"/>
    )
}
